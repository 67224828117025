@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100&display=swap');

:root {
    --main-ease: 0.25s ease;

    --base-white: 255 255 255;
    --base-black: 0 0 0;

    --white: rgb(var(--base-white));
    --black: rgb(var(--base-black));

    --base-main-text: 255 255 255;
    --base-secondary-text: 156 124 53;
    --base-dark-text: 215 215 215;
    --base-sidebar-text: 255 255 255;
    --base-buttons-text: 255 255 255;
    --base-gradient-text: 255 255 255;

    --main-text: rgb(var(--base-main-text));
    --secondary-text: rgb(var(--base-secondary-text));
    --dark-text: rgb(var(--base-dark-text));
    --sidebar-text: rgb(var(--base-sidebar-text));
    --buttons-text: rgb(var(--base-buttons-text));
    --gradient-text: rgb(var(--base-gradient-text));

    --base-disabled: 175 175 175;
    --base-error: 255 105 105;
    --base-success: 55 169 133;
    --base-warning: 245 158 11;

    --disabled: rgb(var(--base-disabled));
    --error: rgb(var(--base-error));
    --error-light: rgb(var(--base-error) / 75%);
    --success: rgb(var(--base-success));
    --success-light: rgb(var(--base-success) / 75%);
    --warning: rgb(var(--base-warning));
    --warning-light: rgb(var(--base-warning) / 75%);

    --base-primary-main: 8 51 105;
    --base-primary-light: 17 81 139;
    --base-primary-dark: 1 29 80;
    --base-secondary-main: 156 124 53;
    --base-secondary-light: 199 163 83;
    --base-secondary-lighter: 216 191 135;
    --base-gradient-primary-light: 17 81 139;
    --base-gradient-primary-dark: 1 29 80;
    --base-gradient-sidebar-main: 5 41 94;
    --base-gradient-sidebar-light: 8 51 105;
    --base-gradient-sidebar-dark: 1 29 80;
    --base-layout-main: 20 20 20;
    --base-layout-light: 32 32 32;
    --base-layout-lighter: 56 56 56;
    --base-layout-transparent: 216 232 255;

    --primary-main: rgb(var(--base-primary-main));
    --primary-transparent: rgb(var(--base-primary-main) / 50%);
    --primary-less-transparent: rgb(var(--base-primary-main) / 75%);
    --primary-light: rgb(var(--base-primary-light));
    --primary-dark: rgb(var(--base-primary-dark));
    --secondary-light: rgb(var(--base-secondary-light));
    --secondary-lighter: rgb(var(--base-secondary-lighter));
    --secondary-main: rgb(var(--base-secondary-main));
    --gradient-primary-light: rgb(var(--base-gradient-primary-light));
    --gradient-primary-dark: rgb(var(--base-gradient-primary-dark));
    --gradient-sidebar-main: rgb(var(--base-gradient-sidebar-main));
    --gradient-sidebar-light: rgb(var(--base-gradient-sidebar-light));
    --gradient-sidebar-dark: rgb(var(--base-gradient-sidebar-dark));
    --layout-main: rgb(var(--base-layout-main));
    --layout-light: rgb(var(--base-layout-light));
    --layout-lighter: rgb(var(--base-layout-lighter));
    --layout-transparent: rgb(var(--base-layout-transparent) / 4%);
    --layout-transparent-dark: rgb(var(--base-layout-transparent) / 12%);

    --base-pdf-text: 0 0 0;
    --base-pdf-link-text: 45 122 255;
    --base-pdf-layout-text: 255 255 255;
    --base-pdf-layout-background: 8 51 105;

    --pdf-text: rgb(var(--base-pdf-text));
    --pdf-link-text: rgb(var(--base-pdf-link-text));
    --pdf-layout-text: rgb(var(--base-pdf-layout-text));
    --pdf-layout-background: rgb(var(--base-pdf-layout-background));
}

body {
    font-family: 'Sarabun', 'sans-serif';
    color: var(--main-text);
}

html {
    font-size: 12px;
}

*::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

*::-webkit-scrollbar-track {
    background-color: var(--layout-transparent-dark);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--primary-main);
    border-radius: 9999px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* --- CLASSES --- */
/* .budgetary-border-selector .budgetary-border-top {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
}

.budgetary-border-selector .budgetary-border-bottom {
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
} */

.budgetary-border-selector .budgetary-parent-selector:last-child {
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}

.budgetary-border-selector .budgetary-parent-selector:last-child .budgetary-child-selector:last-child {
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}

.user-select-none,
.no-user-select-recursive,
.no-user-select-recursive * {
    user-select: none !important;
}

/* .list-line {
    width: 0.5rem;
    height: 1px;
    transform: translateY(0.6rem);
    background-color: #fff;
    margin-right: 0.5rem;
    flex-shrink: 0;
}

.list-circle {
    width: 0.2rem;
    height: 0.2rem;
    border-radius: 50%;
    transform: translateY(0.55rem);
    background-color: #fff;
    margin-right: 0.5rem;
    margin-left: 1rem;
    flex-shrink: 0;
} */

/* Lucide */

.lucide {
    stroke-width: 1.5;
}

h1 {
    font-size: 2.4rem;
    font-weight: 300;
    color: var(--main-text);
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    line-height: 1.1;
}

h2 {
    font-size: 2rem;
    font-weight: 500;
    color: var(--main-text);
    line-height: 1.2;
}

h3 {
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--main-text);
    line-height: 1.2;
}

h4 {
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--main-text);
    line-height: 1.2;
}

h5 {
    font-size: 1rem;
    font-weight: 700;
    color: var(--main-text);
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    line-height: 1.2;
}

h6 {
    font-size: 1rem;
    font-weight: 300;
    color: var(--main-text);
    line-height: 1.2;
}

p {
    font-size: 1rem;
    line-height: 1.2;
    font-weight: 400;
    color: var(--main-text);
}

.page-container {
    padding: 2rem 3.5rem 4rem 3.5rem;
}

@media (max-width: 1250px) {
    .page-container {
        padding: 2rem 2.5rem 4rem 2.5rem;
    }
}

@media (max-width: 750px) {
    .page-container {
        padding: 2rem 1.5rem 4rem 1.5rem;
    }
}

/* --- SEARCH INPUT --- */
.search-input-container {
    display: flex;
    align-items: center;
    position: relative;
}

.search-input-container .search-icon {
    width: 1.5rem;
    height: 1.5rem;
    color: var(--buttons-text);
}

.search-input-container .search-icon-big {
    width: 2.35rem;
    height: 2.35rem;
    color: var(--buttons-text);
}

/* --- SIDEBAR --- */
.main-sidebar {
    position: relative;
    min-height: 100%;
    width: calc(5rem + 2rem);
    flex-shrink: 0;
    align-self: stretch;
    transition: width var(--main-ease);
    z-index: 101;
}

.main-sidebar .inner-sidebar {
    position: absolute;
    top: 2rem;
    bottom: 2rem;
    left: 2rem;
    width: 5rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: linear-gradient(
        to bottom,
        var(--gradient-sidebar-main),
        var(--gradient-sidebar-dark),
        var(--gradient-sidebar-light)
    );
    transition: width 0.35s ease;
    box-shadow: 0 0 10px 0 rgb(var(--base-black / 25%));
    overflow: hidden;
}

.main-sidebar .inner-sidebar-disabled {
    filter: blur(2px);
}

.sidebar-content {
    opacity: 1;
}

.nav-list {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    max-height: 60vh;
}

.nav-list::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.nav-list::-webkit-scrollbar-track {
    background-color: transparent;
}

.nav-list::-webkit-scrollbar-thumb {
    background-color: rgb(var(--base-sidebar-text) / 65%);
    border-radius: 9999px;
}

@media (max-width: 750px) {
    .nav-list {
        max-height: 70vh;
    }
}

.li-icon-width {
    width: 2.5rem;
    height: 2rem;
    color: var(--sidebar-text);
}

@media (max-width: 1250px) {
    .main-sidebar {
        width: calc(5rem + 1rem);
    }

    .main-sidebar .inner-sidebar {
        top: 1rem;
        bottom: 1rem;
        left: 1rem;
    }
}

@media (max-width: 750px) {
    .main-sidebar {
        width: 100%;
        min-height: calc(5rem - 10px);
        position: fixed;
        transition: min-height ease 0.2s;
    }

    .main-sidebar .inner-sidebar {
        width: 100%;
        overflow: visible;
        top: 0;
        bottom: 0;
        left: 0;
        border-radius: 0;
    }

    .sidebar-content {
        opacity: 0;
    }

    .delay-sidebar-content {
        animation: delay-sidebar-content 0.4s forwards;
    }

    @keyframes delay-sidebar-content {
        to {
            opacity: 1;
        }
    }
}

@media (max-width: 750px) {
    .main-sidebar-open {
        min-height: 100svh;
    }
}

@media (min-width: 751px) {
    .main-sidebar:hover .inner-sidebar {
        width: 300px;
    }
}

/* --- RIGHT SIDE --- */
.main-content-area {
    position: relative;
    width: 100%;
    height: 100svh;
    max-height: 100svh;
    flex: 1;
    z-index: 100;
    overflow-y: scroll;
}

@media (max-width: 750px) {
    .main-content-area {
        margin-top: calc(5rem - 10px);
    }
}

/* --- ANIMATION ON HOVER --- */
@media (min-width: 751px) {
    .appear-on-expand {
        transform: translateX(-20px);
        opacity: 0;
        transition:
            opacity 0.35s ease,
            transform 0.35s ease;
    }

    .move-on-expand {
        transform: translateX(-27.5px);
        transition: transform 0.35s ease;
    }

    .move-child-on-expand {
        transform: translateX(-50px);
        transition: transform 0.35s ease;
    }

    .main-sidebar:hover .appear-on-expand {
        transform: translateX(0);
        opacity: 1;
    }

    .main-sidebar:hover .move-on-expand {
        transform: translateX(0);
    }

    .main-sidebar:hover .move-child-on-expand {
        transform: translateX(0);
    }
}

.logo g g path {
    fill: var(--sidebar-text);
}

/* --- NAVIGATION LINK TAB --- */

.nav-link-tab:not(.active-nav-link-tab):not(.disabled-nav-link-tab) div:hover p,
.nav-link-tab:not(.active-nav-link-tab):not(.disabled-nav-link-tab) div:hover p span svg path {
    color: var(--primary-light);
    fill: var(--primary-light);
}

.nav-link-tab:not(.disabled-nav-link-tab) div p {
    color: var(--buttons-text);
}

.nav-link-tab:not(.disabled-nav-link-tab) div p span svg path {
    fill: var(--buttons-text);
}

.nav-link-tab div p span svg path {
    transition: all 150ms ease;
}

.active-nav-link-tab div {
    background-color: var(--primary-light);
}

.active-nav-link-tab div p {
    color: var(--sidebar-text);
}

.disabled-nav-link-tab {
    pointer-events: none;
}

/* --- CONTENT SECTION --- */
.content-section {
    min-height: 100svh;
    display: flex;
    flex-direction: column;
}

@media (max-width: 750px) {
    .content-section {
        min-height: calc(100svh - 5rem + 10px);
    }
}

/* --- DROPDOWN --- */
.dropdown-selected {
    width: 100%;
    padding-right: 0.5rem;
    padding-left: calc(1rem + 1px);
    height: 2.75rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition:
        background-color var(--main-ease),
        border-radius var(--main-ease);
    user-select: none;
    color: var(--buttons-text);
    z-index: 50;
}

.dropdown-selected.is-icon {
    /* width: 2.75rem; */

    gap: 0.25rem;
    padding: 10px !important;
    justify-content: center;
}

.dropdown-selected.default {
    background-color: rgb(var(--base-primary-light) / 50%);
}

.dropdown-selected.default:hover {
    background-color: rgb(var(--base-primary-light) / 75%);
}

.dropdown-selected.green {
    background-color: rgb(var(--base-success) / 50%);
}

.dropdown-selected.green:hover {
    background-color: rgb(var(--base-success) / 75%);
}

.dropdown-selected.black {
    background-color: rgb(var(--base-layout-light) / 50%);
}

.dropdown-selected.black:hover {
    background-color: rgb(var(--base-layout-light) / 75%);
}

.dropdown-selected.secondary {
    background-color: rgb(var(--base-secondary-light) / 50%);
}

.dropdown-selected.secondary:hover {
    background-color: rgb(var(--base-secondary-light) / 75%);
}

.dropdown-selected.hasValue.default {
    background-color: var(--primary-light) !important;
}

.dropdown-selected.hasValue.green {
    background-color: var(--success) !important;
}

.dropdown-selected.hasValue.black {
    background-color: var(--layout-light) !important;
}

.dropdown-selected.hasValue.secondary {
    background-color: var(--secondary-light) !important;
}

.dropdown-selected.disabled {
    background-color: var(--disabled) !important;
}

.dropdown-selected.clicked {
    border-radius: 5px 5px 0 0;
}

.dropdown-selected p {
    width: calc(100% - 15px);
    overflow: hidden;
}

.dropdown-selected > svg {
    flex-shrink: 0;
    margin-left: 0.5rem;
    font-size: 1.25rem;
}

.dropdown-selected.is-icon > svg {
    margin-left: 0 !important;
}

.dropdown-options {
    min-width: calc(100% + 5px);
    border-radius: 0 2.5px 2.5px 2.5px;
    background-color: var(--primary-lighter);
    z-index: 55;
    overflow: hidden;
}

.dropdown-options.default {
    background-color: var(--primary-light);
}

.dropdown-options.green {
    background-color: var(--success);
}

.dropdown-options.secondary {
    background-color: var(--secondary-light);
}

.dropdown-options.black {
    background-color: var(--layout-light);
}

.dropdown-options ul {
    max-height: 20rem;
    overflow-y: scroll;
    overflow-x: hidden;
}

.dropdown-options ul::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.dropdown-options ul::-webkit-scrollbar-track {
    background: transparent !important;
}

/* Handle */
.dropdown-options ul::-webkit-scrollbar-thumb {
    background: rgb(var(--base-white) / 75%) !important;
    border-radius: 999px !important;
}

/* Handle on hover */
.dropdown-options ul::-webkit-scrollbar-thumb:hover {
    background: transparent !important;
}

.dropdown-options ul li {
    color: var(--white);
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    transition: background-color var(--main-ease);
    white-space: nowrap;
    user-select: none;
    gap: 0.5rem;
}

.dropdown-options .search-li {
    padding: 0.5rem;
    border-top-right-radius: 5px;
    border-bottom: 1px solid rgb(var(--base-white) / 10%);
}

.dropdown-options ul li > p {
    user-select: none;
    pointer-events: none;
}

.dropdown-options ul li:hover,
.dropdown-options ul li.active {
    background-color: rgb(var(--base-white) / 15%);
}

/* .dropdown-z-index {
    z-index: 999;
} */

/* --- DATE PICKER --- */
.hidden-default-date-picker {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    user-select: none;
    top: 0;
    left: 50%;
}

/* --- TOGGLE --- */
.toggle-component {
    position: relative;
    border-radius: 999px;
    background-color: var(--layout-main);
    width: 2.5rem;
    height: 1.5rem;
    cursor: pointer;
    user-select: none;
    transition: background-color var(--main-ease);
    overflow: hidden;
}

.toggle-component > div {
    position: absolute;
    left: 0.25rem;
    top: 50%;
    transform: translate(0, -50%);
    width: calc(1rem);
    height: calc(1rem);
    border-radius: 50%;
    transition:
        transform var(--main-ease),
        background-color var(--main-ease);
    background-color: var(--primary-light);
}

.toggle-component.checked {
    background-color: var(--primary-light);
}

.toggle-component.checked > div {
    transform: translate(1rem, -50%);
    background-color: var(--white);
}

.toggle-component.disabled {
    cursor: not-allowed;
    background-color: var(--disabled);
}

.toggle-component.disabled > div {
    background-color: var(--white);
}

/* --- INPUT GROUP --- */
.input-group {
    border-left: 1px solid rgb(var(--bass-white) / 5%);
    transition: border-color var(--main-ease);
}

.input-group:hover {
    border-color: rgb(var(--base-white) / 25%);
}

/* --- ACTION BUTTON --- */
.action-button {
    transition: filter 0.2s ease;
}
.action-button:hover {
    filter: brightness(0.75);
}

.action-button * {
    color: var(--buttons-text) !important;
}

/* Mui  override */
.MuiPickersClock-clock {
    background-color: rgb(var(--primary-main) / 50%) !important;
}

body > iframe {
    display: none !important;
    pointer-events: none !important;
    visibility: hidden !important;
}

.pipeline-container::webkit-scrollbar {
    width: 0 !important;
    display: none;
    overflow-x: scroll;
}

.no-child-pointer-events * {
    pointer-events: none;
    user-select: none;
}

/* .Example header {
    background-color: #323639;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    padding: 20px;
    color: white;
}

.Example header h1 {
    font-size: inherit;
    margin: 0;
} */

.Example__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Example__container__document .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.Example__container__document .react-pdf__Page {
    width: 100%;
    height: 100%;
}

.Example__container__document .react-pdf__Page canvas {
    width: 100% !important;
    height: 100% !important;
    border-radius: 15px;
}

.Example__container__document .react-pdf__message {
    padding: 20px;
    color: var(--main-text);
}

@media (max-width: 600px) {
    .Example__container__document .react-pdf__Page {
        width: 400px;
    }
}

@media (max-width: 400px) {
    .Example__container__document .react-pdf__Page {
        width: 350px;
    }
}

/* Gantt wrapper class */

.gantt-wrapper::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 1px solid rgb(192, 192, 192);
    pointer-events: none;
}

/* Projects Gantt border class */

.gantt-extreme-border {
    border-color: rgb(var(--base-layout-transparent));
}

/* Blur */

.text-blur {
    color: transparent !important;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
}

/* Template document */

.display-elements {
    animation: displayElements 300ms cubic-bezier(0.4, 0, 0.2, 1) 300ms backwards;
}

@keyframes displayElements {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.hide-elements {
    animation: hideElements 300ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes hideElements {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* Basic Tooltip */
.basic-tooltip .basic-tooltip-toggle {
    opacity: 0;
}

.basic-tooltip:hover .basic-tooltip-toggle {
    opacity: 1;
}

.basic-tooltip .basic-tooltip-disabled {
    opacity: 0;
}

/* This hide arrows from an input by number type */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
.wrapperClassName .rdw-option-wrapper {
    border: 1px solid var(--layout-lighter);
    padding: 5px;
    min-width: 30px;
    height: 30px;
    border-radius: 2px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: var(--white);
    text-transform: capitalize;
}
